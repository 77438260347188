@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

input {
  color-scheme: light;
  background-color:white;
}
input:focus{
  outline:none
}

/* Loader1 CSS  */
.custom-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #766df4 94%, #0000) top/4px 4px
      no-repeat,
    conic-gradient(#0000 30%, #766df4);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 2s infinite linear;
}

/* wave loader css start */

.dot-wave {
  --uib-size: 47px;
  --uib-speed: 1s;
  --uib-color: black;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: calc(var(--uib-size) * 0.17);
  padding-top: calc(var(--uib-size) * 0.34);
}

.dot-wave__dot {
  flex-shrink: 0;
  width: calc(var(--uib-size) * 0.17);
  height: calc(var(--uib-size) * 0.17);
  border-radius: 50%;
  background-color: var(--uib-color);
  will-change: transform;
}

.dot-wave__dot:nth-child(1) {
  animation: jump var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.45) infinite;
}

.dot-wave__dot:nth-child(2) {
  animation: jump var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.3) infinite;
}

.dot-wave__dot:nth-child(3) {
  animation: jump var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.15) infinite;
}

.dot-wave__dot:nth-child(4) {
  animation: jump var(--uib-speed) ease-in-out infinite;
}


@keyframes jump {
  0%,
  100% {
    transform: translateY(0px);
  }
  
  50% {
    transform: translateY(-200%);
  }
}
/* wave loader css end */

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
